import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import HeroSection from '../Herosection';
import AboutUs from '../AboutUs';
import CardLinks from '../CardLinks';
import Achievements from '../Achivements';

function Home() {
  return (
    <>
      <HeroSection/>
      {/* <CardLinks/> */}
      <AboutUs/>
      <Achievements/>
    </>
  );
}

export default Home;