import { FaCheckCircle } from "react-icons/fa";
import "./About.css";

const services = [
  {
    category: "CV Preparation (ATS-Friendly & Career Transition Focused)",
    image: "images/personal branding.png",
    items: [
      "Keyword-Specific (ATS-Based)",
      "Company-Specific",
      "Country/Region-Specific",
    ],
  },
  {
    category: "Interview Preparation (For Job Seekers & Career Changers)",
    image: "images/interview prep.png",
    items: [
      "Company-Specific Mock Interviews",
      "General Interview Coaching",
      "Follow-Up & Salary Negotiation Guidance",
    ],
  },
  {
    category: "Internship & Training",
    image: "images/internship.png",
    items: [
      "Internship Placement Assistance",
      "Skill-Based Training for Industry Readiness",
    ],
  },
  {
    category: "Career Transition Support",
    image: "images/transition.png",
    items: [
      "Tailored Resume & Interview Prep for Career Changers",
      "Strategies for Re-Entering the Job Market",
    ],
  },
  {
    category: "Personal Branding & Online Presence",
    image: "images/last.png",
    items: [
      "LinkedIn Optimization for Job Visibility",
      "Professional Branding on Instagram & Facebook",
      "Ensuring Employers Discover the Right Profile",
    ],
  },
];

export default function ServicesPage() {
  return (
    <div className="services-container">
      <h1 className="services-title">Our Services</h1>
      <div className="services-grid">
        {services.map((service, index) => (
          <div key={index} className="service-card">
            <img src={service.image} alt={service.category} className="service-image" />
            <h2 className="service-title">{service.category}</h2>
            <ul className="service-list">
              {service.items.map((item, idx) => (
                <li key={idx}>
                  <FaCheckCircle className="service-icon" /> {item}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}
