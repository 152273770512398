import "./Company.css"; 

const jobs = [
  {
    title: "Sales Officer",
    location: "Nippon Toyota",
    jobType: "Permanent",
    vacancies: 5,
    experience: "Nill",
    qualification: "Any Degree",
    gender: "Male",
    skills: "Communication Skill",
    description: "New Toyota Vehicle's sales through field work and various activities."
  },
  {
    title: "Marketing Executive",
    location: "Nippon Toyota",
    jobType: "Permanent",
    vacancies: 3,
    experience: "Nill",
    qualification: "Any Degree",
    gender: "Male",
    skills: "Communication Skill",
    description: "Introducing Toyota vehicles to customers through events and marketing activities."
  },
  {
    title: "Management Trainee",
    location: "Nippon Toyota",
    jobType: "Permanent",
    vacancies: 3,
    experience: "Nill",
    qualification: "MBA",
    gender: "Male",
    skills: "Communication Skill",
    description: "Learning platform for sales and marketing activities."
  },
  {
    title: "Apprentice Trainee",
    location: "Nippon Toyota",
    jobType: "Internship",
    vacancies: 5,
    experience: "Nill",
    qualification: "ITI NCVT",
    gender: "Male",
    skills: "Technical Skill",
    description: "1-year apprenticeship program with stipend."
  }
];

function Company1() {
  return (
    <div className="company-container">
      <header className="company-header">
        <h1 className="company-title">Nippon Toyota</h1>
        <img src="images/nippon.png" alt="Company Logo" className="company-logo" />
      </header>
      
      <section className="job-listings">
        {jobs.map((job, index) => (
          <section key={index} className="job-section">
            <h2 className="job-title">{job.title}</h2>
            <div className="job-details">
              <p><strong>Location:</strong> {job.location}</p>
              <p><strong>Job Type:</strong> {job.jobType}</p>
              <p><strong>Number of Vacancies:</strong> {job.vacancies}</p>
              <p><strong>Experience:</strong> {job.experience}</p>
              <p><strong>Qualification:</strong> {job.qualification}</p>
              <p><strong>Gender:</strong> {job.gender}</p>
              <p><strong>Skills Required:</strong> {job.skills}</p>
            </div>
            <h3>Job Description:</h3>
            <p>{job.description}</p>
          </section>
        ))}
      </section>

      <footer className="apply-section">
        <a href="https://forms.gle/sw88CzWEMzgBhEUt6" target="_blank" rel="noopener noreferrer">
          <button className="apply-button">Apply Now</button>
        </a>
      </footer>
    </div>
  );
}

export default Company1;
