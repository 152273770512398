import "./Company.css";

const jobs = [
  {
    title: "Subordinate Manager",
    location: "Westhill, Calicut",
    jobType: "Permanent",
    description: "Oversee and manage subordinate teams to ensure smooth operations and productivity.",
    responsibilities: [
      "Supervise and coordinate daily activities of team members.",
      "Develop and implement operational strategies.",
      "Monitor employee performance and provide feedback.",
      "Ensure compliance with company policies and procedures.",
      "Assist in recruitment and training of new team members."
    ],
    requirements: [
      "Bachelor's degree in Management or a related field.",
      "Proven experience in a managerial role.",
      "Strong leadership and communication skills.",
      "Ability to multitask and work under pressure.",
      "Knowledge of industry regulations and compliance."
    ]
  },
  {
    title: "Business Development Manager",
    location: "Westhill, Calicut",
    jobType: "Permanent",
    description: "Lead business growth strategies, identify new opportunities, and build client relationships.",
    responsibilities: [
      "Develop and implement business strategies to drive growth.",
      "Identify and pursue new business opportunities.",
      "Build and maintain strong client relationships.",
      "Negotiate contracts and close deals.",
      "Collaborate with marketing and sales teams."
    ],
    requirements: [
      "Bachelor's degree in Business, Marketing, or a related field.",
      "Proven experience in business development or sales.",
      "Excellent communication and negotiation skills.",
      "Strong analytical and problem-solving abilities.",
      "Ability to work independently and as part of a team."
    ]
  },
  {
    title: "Territory Sales Manager",
    location: "Westhill, Calicut",
    jobType: "Permanent",
    description: "Manage and oversee sales operations in assigned territories to achieve targets.",
    responsibilities: [
      "Develop and execute sales strategies within the territory.",
      "Build relationships with customers and business partners.",
      "Analyze market trends and adjust strategies accordingly.",
      "Train and manage sales teams in assigned regions.",
      "Monitor sales performance and report to senior management."
    ],
    requirements: [
      "Bachelor's degree in Business, Marketing, or a related field.",
      "Experience in sales and territory management.",
      "Excellent leadership and organizational skills.",
      "Strong negotiation and communication abilities.",
      "Proficiency in CRM software and sales analytics."
    ]
  },
  {
    title: "HR Manager",
    location: "Westhill, Calicut",
    jobType: "Permanent & Internship",
    description: "Oversee HR functions, including recruitment, employee relations, and compliance.",
    responsibilities: [
      "Manage end-to-end recruitment processes.",
      "Develop employee engagement and training programs.",
      "Ensure compliance with labor laws and company policies.",
      "Handle performance appraisals and employee grievances.",
      "Collaborate with management to improve workplace culture."
    ],
    requirements: [
      "Bachelor's degree in Human Resources or a related field.",
      "Experience in HR management or related roles.",
      "Strong interpersonal and problem-solving skills.",
      "Knowledge of labor laws and best HR practices.",
      "Ability to handle confidential information with discretion."
    ]
  },
  {
    title: "Area Sales Manager",
    location: "Westhill, Calicut",
    jobType: "Permanent",
    description: "Drive sales and business expansion in the assigned area through strategic planning.",
    responsibilities: [
      "Develop and execute sales plans to achieve revenue targets.",
      "Supervise and support sales teams in the region.",
      "Identify and implement new market opportunities.",
      "Ensure customer satisfaction and build strong relationships.",
      "Analyze competitor activities and adjust sales strategies accordingly."
    ],
    requirements: [
      "Bachelor's degree in Business, Marketing, or a related field.",
      "Proven experience in sales leadership roles.",
      "Strong analytical and strategic planning skills.",
      "Excellent communication and team management abilities.",
      "Ability to work in a fast-paced environment."
    ]
  }
];

function Aytech() {
  return (
    <div className="company-container">
      <header className="company-header">
        <h1 className="company-title">LA EARTH</h1>
        <img src="images/laearth.jpg" alt="Company Logo" className="company-logo" />
      </header>
      
      <section className="job-listings">
        {jobs.map((job, index) => (
          <section key={index} className="job-section">
            <h2 className="job-title">{job.title}</h2>
            <div className="job-details">
              <p><strong>Location:</strong> {job.location}</p>
              <p><strong>Job Type:</strong> {job.jobType}</p>
            </div>
            <h3>Job Description:</h3>
            <p>{job.description}</p>
            <h3>Responsibilities:</h3>
            <ul>
              {job.responsibilities.map((res, i) => <li key={i}>{res}</li>)}
            </ul>
            <h3>Requirements:</h3>
            <ul>
              {job.requirements.map((req, i) => <li key={i}>{req}</li>)}
            </ul>
          </section>
        ))}
      </section>

      <footer className="apply-section">
        <a href="https://forms.gle/sw88CzWEMzgBhEUt6" target="_blank" rel="noopener noreferrer">
          <button className="apply-button">Apply Now</button>
        </a>
      </footer>
    </div>
  );
}

export default Aytech;
