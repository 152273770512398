import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Jobs.css";


const jobsData = [
    { 
      title: "Junior Software Engineer", 
      company: "AY TECH", 
      location: "Kottayam", 
      jobtype: "Full-time", 
      description: "We are seeking a motivated Junior Software Engineer to join our development team. The ideal candidate should have a strong passion for coding, problem-solving, and learning new technologies." 
    },
    { 
      title: "Full Stack Developer", 
      company: "AY TECH", 
      location: "TVM/Kochi", 
      jobtype: "Full-time", 
      description: "We are looking for a skilled Full Stack Developer to develop and maintain web applications. The ideal candidate should have expertise in both front-end and back-end technologies." 
    },
    { 
      title: "Graphic Designer", 
      company: "AY TECH", 
      location: "TVM/Kochi", 
      jobtype: "Full-time", 
      description: "We are hiring a Graphic Designer to create visually engaging content for digital and print media. The ideal candidate should have a strong eye for design and creativity." 
    },
    { 
      title: "Business Development Executive", 
      company: "AY TECH", 
      location: "TVM/Kochi", 
      jobtype: "Full-time", 
      description: "We are looking for a dynamic Business Development Executive to drive company growth and build strong client relationships." 
    },
    { 
      title: "Field Executive", 
      company: "Kalyan Jewellers", 
      location: "Multiple Locations", 
      jobtype: "Full-time", 
      description: "Engage with potential customers and promote Kalyan Jewellers' products. Provide excellent customer service and ensure customer satisfaction." 
    },
    { 
      title: "Junior Sales Executive", 
      company: "Kalyan Jewellers", 
      location: "Multiple Locations", 
      jobtype: "Full-time", 
      description: "Assist customers in selecting products and provide a great shopping experience. Achieve sales targets and contribute to store growth." 
    },
    { 
      title: "Marketing Executive", 
      company: "Kalyan Jewellers", 
      location: "Multiple Locations", 
      jobtype: "Full-time", 
      description: "Promote Kalyan Jewellers’ brand and products to potential customers. Engage in field sales activities to drive customer acquisition and sales growth." 
    },
    { 
      title: "Sales Coordinator (Telecalling)", 
      company: "Kalyan Jewellers", 
      location: "Multiple Locations", 
      jobtype: "Full-time", 
      description: "Conduct follow-up calls to customers post-purchase to ensure satisfaction. Address customer concerns and provide necessary assistance." 
    },
    { 
      title: "Sales Executive Trainee", 
      company: "Kalyan Jewellers", 
      location: "Multiple Locations", 
      jobtype: "Full-time", 
      description: "Assist customers in selecting products and provide excellent customer service. Support the sales team in achieving targets." 
    },
    { 
      title: "Sales Executive", 
      company: "Kalyan Jewellers", 
      location: "Multiple Locations", 
      jobtype: "Full-time", 
      description: "Conduct follow-up calls to customers post-purchase, ensuring satisfaction and addressing any concerns. Build lasting relationships with customers." 
    },
    { 
      title: "Sales Officer", 
      company: "Nippon Toyota", 
      location: "Multiple Locations", 
      jobtype: "Permanent", 
      description: "New Toyota Vehicle's sales through field work and various activities." 
    },
    { 
      title: "Marketing Executive", 
      company: "Nippon Toyota", 
      location: "Multiple Locations", 
      jobtype: "Permanent", 
      description: "Introducing Toyota vehicles to customers through events and marketing activities." 
    },
    { 
      title: "Management Trainee", 
      company: "Nippon Toyota", 
      location: "Multiple Locations", 
      jobtype: "Permanent", 
      description: "Learning platform for sales and marketing activities." 
    },
    { 
      title: "Apprentice Trainee", 
      company: "Nippon Toyota", 
      location: "Multiple Locations", 
      jobtype: "Internship", 
      description: "1-year apprenticeship program with stipend." 
    },
    { 
      title: "Customer Support Executive", 
      company: "GJ Infotechs", 
      location: "Multiple Locations", 
      jobtype: "Full-time", 
      description: "Installation & configuration of software systems, call and site support, staff training, software demonstration, and issue tracking." 
    },
    { 
      title: "Marketing Executive", 
      company: "GJ Infotechs", 
      location: "Multiple Locations", 
      jobtype: "Full-time", 
      description: "Develop and execute marketing strategies, conduct market research, generate leads, and drive customer engagement for software products." 
    },
      { 
        title: "Subordinate Manager", 
        company: "LA-EARTH GROUP", 
        location: "Westhill, Calicut", 
        jobtype: "Permanent", 
        description: "Manage subordinate teams and oversee operations."
      },
      { 
        title: "Business Development Manager", 
        company: "LA-EARTH GROUP", 
        location: "Westhill, Calicut", 
        jobtype: "Permanent", 
        description: "Develop business strategies and partnerships."
      },
      { 
        title: "Territory Sales Manager", 
        company: "LA-EARTH GROUP", 
        location: "Westhill, Calicut", 
        jobtype: "Permanent", 
        description: "Manage sales across assigned territories."
      },
      { 
        title: "HR", 
        company: "LA-EARTH GROUP", 
        location: "Westhill, Calicut", 
        jobtype: "Both (Permanent & Internship)", 
        description: "Handle recruitment and employee management."
      },
      { 
        title: "Area Sales Manager", 
        company: "LA-EARTH GROUP", 
        location: "Westhill, Calicut", 
        jobtype: "Permanent", 
        description: "Oversee regional sales and marketing efforts."
      },
      { 
        title: "Marketing Executive", 
        company: "GJ Infotechs", 
        location: "Multiple Locations", 
        jobtype: "Full-time", 
        description: "Develop and execute marketing strategies, conduct market research, generate leads, and drive customer engagement for software products."
      }
    ];

function JobPage() {
  const [filters, setFilters] = useState({ company: "", title: "" });
  const navigate = useNavigate();

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };
  
  const handleCardClick = (job) => {
    // Navigate to the company-specific page based on the company name
    switch (job.company) {
      case "AY TECH":
        navigate("/aytech");
        break;
      case "Kalyan Jewellers":
        navigate("/kalyan-jewellers");
        break;
      case "Nippon Toyota":
        navigate("/nippon-toyota");
        break;
      case "GJ Infotechs":
        navigate("/gj-infotechs");
        break;
        case "LA-EARTH GROUP":
          navigate("/laearth");
          break;
      default:
        navigate("/");
    }
  };

  const filteredJobs = jobsData.filter(job => {
    return (
      (filters.company === "" || job.company.includes(filters.company)) &&
      (filters.title === "" || job.title.includes(filters.title))
    );
  });

  return (
    <div className="job-page-container">
      <h1 className="page-title">Available Job Roles</h1>
      <div className="filter-options">
        <label>Filter by Company: </label>
        <select name="company" onChange={handleFilterChange} value={filters.company}>
          <option value="">All</option>
          {[...new Set(jobsData.map(job => job.company))].map(company => (
            <option key={company} value={company}>{company}</option>
          ))}
        </select>
        <label>Filter by Job Role: </label>
        <select name="title" onChange={handleFilterChange} value={filters.title}>
          <option value="">All</option>
          {[...new Set(jobsData.map(job => job.title))].map(title => (
            <option key={title} value={title}>{title}</option>
          ))}
        </select>
      </div>
      <div className="job1-listings">
        {filteredJobs.map((job, index) => (
          <div key={index} className="job1-card" onClick={() => handleCardClick(job)}>
            <h2 className="job1-title">{job.title}</h2>
            <p className="company-name">{job.company}</p>
            <p className="location"><strong>Location:</strong> {job.location}</p>
            <p className="job1-type"><strong>Job Type:</strong> {job.jobtype}</p>
            <p className="job1-description">{job.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default JobPage;