import "./Company.css"; 

const jobs = [
  {
    title: "Customer Support Executive",
    location: "CSE",
    jobType: "Permanent",
    gender: "Male",
    description: "Installation & configuration of software systems and applications. Call Support & Site support – Make solutions for problems. Provide support to all departments, staff training, and assisting QA/QC teams when available. Maintain visit reports and submit expenses weekly. Conduct software demonstrations, training, and other support-related activities. Record and share reported issues and modifications with caretakers. Collect and analyze customer feedback. Gather samples like report cards and fee bills. Ensure support team familiarity with module differentiations."
  },
  {
    title: "Marketing Executive",
    location: "Marketing Department",
    jobType: "Permanent",
    gender: "Male",
    description: "Develop and execute marketing strategies for software products and services. Conduct market research, analyze customer data, and generate qualified leads. Track and evaluate leads to conversion. Execute marketing initiatives such as digital campaigns, social media engagement, and event promotions. Support the sales process from lead generation to installation. Identify market trends and develop business strategies to meet goals."
  },
  {
    title: "Software Development Trainee",
    location: "Development Team",
    jobType: "Internship",
    gender: "Male/Female",
    description: "Analyze user needs and design software solutions. Write, test, and deploy clean and secure code. Maintain software and recommend upgrades. Collaborate with senior developers and participate in software development projects. Gain hands-on experience in software architecture, coding, and debugging."
  },
  {
    title: "Software Developer (.NET, Angular, Flutter)",
    location: "Development Team",
    jobType: "Permanent",
    gender: "Male/Female",
    description: "Design algorithms and flowcharts. Write clean and efficient code. Integrate software components and third-party programs. Verify and deploy systems. Debug and upgrade existing software. Gather and evaluate user feedback. Create technical documentation and execute improvements. Experience with coding languages, databases, and ORM frameworks required."
  }
];

function Company1() {
  return (
    <div className="company-container">
      <header className="company-header">
        <h1 className="company-title">GJ INFOTECH</h1>
        <img src="images/gjlogo.png" alt="Company Logo" className="company-logo" />
      </header>
      
      <section className="job-listings">
        {jobs.map((job, index) => (
          <section key={index} className="job-section">
            <h2 className="job-title">{job.title}</h2>
            <div className="job-details">
              <p><strong>Location:</strong> {job.location}</p>
              <p><strong>Job Type:</strong> {job.jobType}</p>
              <p><strong>Gender:</strong> {job.gender}</p>
            </div>
            <h3>Job Description:</h3>
            <p>{job.description}</p>
          </section>
        ))}
      </section>

      <footer className="apply-section">
        <a href="https://forms.gle/sw88CzWEMzgBhEUt6" target="_blank" rel="noopener noreferrer">
          <button className="apply-button">Apply Now</button>
        </a>
      </footer>
    </div>
  );
}

export default Company1;
