import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";


export default function CustomFooter() {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="footer-links">
          <div className="column">
            <h4>Links</h4>
            <a href="#">Home</a>
            <a href="#">Services</a>
            <a href="#">Events</a>
            <a href="#">Gallery</a>
          </div>
          <div className="column">
            <h4>Resources</h4>
            <a href="#">Edu-Job Fair</a>
            <a href="#">Interview Prep</a>
            <a href="#">Get your CV Done</a>
          </div>
          <div className="column">
            <h4>Know us</h4>
            <a href="#">FAQs</a>
            <a href="https://maps.app.goo.gl/qMMpPNtFZkrLsT6r5">Address</a>
            <a href="#">9072939555</a>
          </div>
        </div>

        <div className="footer-call-to-action">
          <p>Nurturing potential at every stages of life</p>
          <h2>Peter's Foundation</h2>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="policy-links">
          <p>Cookies Policy</p>
          <p>Legal Terms</p>
          <p>Privacy Policy</p>
        </div>

        <div className="social-icons">
          <a href="https://www.facebook.com/people/Peters-Foundation/61567172879940/"><FaFacebookF /></a>
          <a href="https://www.instagram.com/petersfoundationin?igsh=dmpuanFsZnNvM245/"><FaInstagram /></a>
          <a href="https://www.youtube.com/@PetersFoundation"><FaYoutube /></a>
        </div>
      </div>
    </footer>
  );
}

