import React from 'react'
import { motion } from "framer-motion";
import "./Logos.css"; // 

const images = [
  "images/milo.jpeg",
  "images/bellp.jpeg",
  "images/laearth.jpg",
  "images/nippon.png",
  "images/tesla.jpeg",
  "images/gjlogo.png",
  "images/kj.png",
];

export default function LogoCarousel() {
    return (
      <div className="carousel-container">
        <motion.div
          className="carousel-track"
          animate={{ x: [0, -300] }}
          transition={{ repeat: Infinity, duration: 5, ease: "linear" }}
        >
          {images.concat(images).map((src, index) => (
            <img
              key={index}
              src={src}
              alt={`logo-img-${index}`}
              className="carousel-image"
            />
          ))}
        </motion.div>
      </div>
    );
  }
  