import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './components/pages/Home';
import Navbar from './components/Navbar';
import About from './components/About';
import Event from './components/Event';
import Gallery from './components/Gallery';
import Footer from './components/Footer';
import Jobs from './components/Jobfair';
import Logos from './components/Logos';
import Aytech from "./components/companies/Aytech"; // Updated path
import KalyanJewellers from "./components/companies/KalyanJewellers"; // Updated path
import NipponToyota from "./components/companies/NipponToyota"; // Updated path
import GJInfotechs from "./components/companies/GJInfotechs"; // Updated path
import Laearth from "./components/companies/Laearth";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={ <About/>} />
        <Route path="/events" element={ <Event/>} />
        <Route path="/gallery" element={ <Gallery/>} /> 
        <Route path="/job" element={ <Jobs/>} /> 
        <Route path="/aytech" element={<Aytech />} />
        <Route path="/kalyan-jewellers" element={<KalyanJewellers />} />
        <Route path="/nippon-toyota" element={<NipponToyota />} />
        <Route path="/gj-infotechs" element={<GJInfotechs />} />
        <Route path="/laearth" element={ <Laearth/>} /> 
      </Routes>
      <Logos/>
      <Footer/>
    </div>
  );
}

export default App;
