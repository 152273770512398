import "./Company.css";

const jobs = [
  {
    title: "Junior Software Engineer",
    location: "Kottayam",
    jobType: "Full-time",
    description: "We are seeking a motivated Junior Software Engineer to join our development team. The ideal candidate should have a strong passion for coding, problem-solving, and learning new technologies.",
    responsibilities: [
      "Assist in developing, testing, and maintaining software applications.",
      "Collaborate with senior developers to improve software functionality.",
      "Write clean, efficient, and scalable code.",
      "Debug and troubleshoot issues as they arise.",
      "Participate in code reviews and team meetings."
    ],
    requirements: [
      "Bachelor's degree in Computer Science, IT, or a related field.",
      "Proficiency in programming languages such as Java, Python, or C++.",
      "Basic knowledge of databases, algorithms, and data structures.",
      "Strong problem-solving and analytical skills.",
      "Willingness to learn and adapt to new technologies."
    ]
  },
  {
    title: "Full Stack Developer",
    location: "TVM/Kochi",
    jobType: "Full-time",
    description: "We are looking for a skilled Full Stack Developer to develop and maintain web applications. The ideal candidate should have expertise in both front-end and back-end technologies.",
    responsibilities: [
      "Develop and maintain scalable web applications.",
      "Design user interfaces with modern frameworks (React, Angular, Vue).",
      "Build and manage databases and APIs.",
      "Optimize applications for performance and scalability.",
      "Collaborate with cross-functional teams for project development."
    ],
    requirements: [
      "Bachelor's degree in Computer Science, IT, or a related field.",
      "Proficiency in front-end technologies (HTML, CSS, JavaScript, React/Angular).",
      "Strong backend development skills (Node.js, Python, Java, etc.).",
      "Experience with databases (SQL, MongoDB, PostgreSQL).",
      "Knowledge of version control systems (Git).",
      "Strong problem-solving skills and ability to work in a team."
    ]
  },
  {
    title: "Graphic Designer",
    location: "TVM/Kochi",
    jobType: "Full-time",
    description: "We are hiring a Graphic Designer to create visually engaging content for digital and print media. The ideal candidate should have a strong eye for design and creativity.",
    responsibilities: [
      "Design logos, social media graphics, and marketing materials.",
      "Create UI/UX designs for websites and mobile applications.",
      "Work closely with marketing teams to develop brand visuals.",
      "Use design software like Adobe Photoshop, Illustrator, and Figma.",
      "Stay updated with design trends and best practices."
    ],
    requirements: [
      "Bachelor's degree in Graphic Design, Fine Arts, or a related field.",
      "Proficiency in Adobe Creative Suite (Photoshop, Illustrator, InDesign).",
      "Strong portfolio showcasing design skills.",
      "Knowledge of UI/UX design principles.",
      "Ability to work under tight deadlines and manage multiple projects."
    ]
  },
  {
    title: "Business Development Executive",
    location: "TVM/Kochi",
    jobType: "Full-time",
    description: "We are looking for a dynamic Business Development Executive to drive company growth and build strong client relationships.",
    responsibilities: [
      "Identify and develop new business opportunities.",
      "Build and maintain client relationships.",
      "Conduct market research and competitive analysis.",
      "Develop and present sales pitches and proposals.",
      "Collaborate with internal teams to improve sales strategies."
    ],
    requirements: [
      "Bachelor's degree in Business, Marketing, or a related field.",
      "Proven experience in business development or sales.",
      "Strong communication and negotiation skills.",
      "Ability to identify new market opportunities.",
      "Goal-oriented and self-motivated."
    ]
  }
];

function Aytech() {
  return (
    <div className="company-container">
      <header className="company-header">
        <h1 className="company-title">AY TECH</h1>
        <img src="images/Aytech logo.jpeg" alt="Company Logo" className="company-logo" />
      </header>
      
      <section className="job-listings">
        {jobs.map((job, index) => (
          <section key={index} className="job-section">
            <h2 className="job-title">{job.title}</h2>
            <div className="job-details">
              <p><strong>Location:</strong> {job.location}</p>
              <p><strong>Job Type:</strong> {job.jobType}</p>
            </div>
            <h3>Job Description:</h3>
            <p>{job.description}</p>
            <h3>Responsibilities:</h3>
            <ul>
              {job.responsibilities.map((res, i) => <li key={i}>{res}</li>)}
            </ul>
            <h3>Requirements:</h3>
            <ul>
              {job.requirements.map((req, i) => <li key={i}>{req}</li>)}
            </ul>
          </section>
        ))}
      </section>

      <footer className="apply-section">
        <a href="https://forms.gle/sw88CzWEMzgBhEUt6" target="_blank" rel="noopener noreferrer">
          <button className="apply-button">Apply Now</button>
        </a>
      </footer>
    </div>
  );
}

export default Aytech;
