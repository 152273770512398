import React from 'react';
import './Achievements.css';

function Achievements() {
  return (
    <div id="about" className="about-container">
      <h1 className="playwrite-gb-s-about-h1">Achievements</h1>
      <div className="about-content">
        <div className="achievements-grid">
          <div className="achievements-image">
            <img src="images/joel.jpeg" alt="Achievements" className="achievements-img" />
          </div>
          <div className="achievements-details">
            <p>
              Our journey has been filled with remarkable milestones. From organizing successful events to empowering individuals,
              Peter’s Foundation continues to make a meaningful impact. Here are some of our key achievements:
            </p>
            <p> <b>Success Story: Congratulations, Joel!</b> <br></br>
Peter's Foundation is thrilled to celebrate the incredible success of Mr. Joel a piping engineer graduate from Christ Engineering college, who transitioned from L&T, Mumbai to a prestigious role at Worley, Australia.
Through our Think Big Interview Preparation Program, Joel received tailored guidance and training to refine his skills, build confidence, and excel in his interviews. His achievement reflects the power of preparation and determination!
We are proud to be part of his journey and look forward to empowering many more individuals to achieve their dreams.
For expert interview training, contact us at +91 99462 83777. Together, let’s create success stories!
</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Achievements;
