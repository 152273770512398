import "./Company.css"; 

const jobs = [
  {
    title: "Field Executive",
    location: "Multiple Locations",
    jobType: "Full-time",
    experience: "Fresher",
    qualification: "Plus Two",
    gender: "Male/Female",
    description: "Engage with potential customers and promote Kalyan Jewellers' products while providing excellent customer service.",
    responsibilities: [
      "Engage with potential customers and promote Kalyan Jewellers' products.",
      "Provide excellent customer service and ensure customer satisfaction.",
      "Facilitate sales by understanding customer needs and offering suitable recommendations.",
      "Maintain showroom standards and represent the brand professionally.",
      "Proactively approach sales opportunities and meet sales targets."
    ],
    requirements: [
      "Strong communication and interpersonal skills.",
      "A proactive and enthusiastic approach to sales.",
      "Passion for the jewellery industry and customer service.",
      "Willingness to work in a field sales role."
    ]
  },
  {
    title: "Junior Sales Executive",
    location: "Multiple Locations",
    jobType: "Full-time",
    experience: "Minimum 2 years in retail sales",
    qualification: "Plus Two",
    gender: "Male",
    description: "Assist customers in selecting products and provide a great shopping experience.",
    responsibilities: [
      "Assist customers in selecting products and provide a great shopping experience.",
      "Understand customer preferences and recommend suitable products.",
      "Achieve sales targets and contribute to store growth.",
      "Maintain store display and ensure proper merchandising."
    ],
    requirements: [
      "Smart and presentable personality.",
      "Excellent communication skills.",
      "Minimum 2 years of experience in retail sales.",
      "Strong customer service and sales skills."
    ]
  },
  {
    title: "Marketing Executive",
    location: "Multiple Locations",
    jobType: "Full-time",
    experience: "Minimum 1 year in field sales/marketing",
    qualification: "Plus Two",
    gender: "Male/Female",
    description: "Promote Kalyan Jewellers’ brand and products to potential customers.",
    responsibilities: [
      "Promote Kalyan Jewellers’ brand and products to potential customers.",
      "Engage in field sales activities to drive customer acquisition and sales growth.",
      "Provide excellent customer service and ensure high customer satisfaction.",
      "Maintain showroom standards and uphold the brand image.",
      "Develop and maintain strong relationships with customers and business partners.",
      "Work proactively to meet sales and marketing targets."
    ],
    requirements: [
      "Strong communication and interpersonal skills.",
      "A proactive approach to sales and marketing.",
      "Passion for the jewellery industry and customer engagement.",
      "Ability to work in a field sales environment."
    ]
  },
  {
    title: "Sales Coordinator (Telecalling)",
    location: "Multiple Locations",
    jobType: "Full-time",
    experience: "Fresher",
    qualification: "Plus Two",
    gender: "Female",
    description: "Conduct follow-up calls to customers post-purchase to ensure satisfaction and address concerns.",
    responsibilities: [
      "Conduct follow-up calls to customers post-purchase to ensure satisfaction.",
      "Address customer concerns and provide necessary assistance.",
      "Gather feedback to improve customer experience and service quality.",
      "Build and maintain strong customer relationships.",
      "Identify opportunities for cross-selling and upselling.",
      "Maintain accurate records of customer interactions."
    ],
    requirements: [
      "Excellent communication and interpersonal skills.",
      "Customer-focused with a problem-solving mindset.",
      "Ability to handle customer concerns professionally.",
      "Basic knowledge of sales and customer service."
    ]
  },
  {
    title: "Sales Executive Trainee",
    location: "Multiple Locations",
    jobType: "Full-time",
    experience: "Fresher",
    qualification: "Plus Two",
    gender: "Male/Female",
    description: "Assist customers in selecting products and provide excellent customer service.",
    responsibilities: [
      "Assist customers in selecting products and provide excellent customer service.",
      "Understand customer needs and recommend suitable products.",
      "Support the sales team in achieving targets.",
      "Maintain store hygiene and merchandise display."
    ],
    requirements: [
      "Strong flair for sales.",
      "Energetic and enthusiastic personality.",
      "Good communication skills.",
      "Positive attitude towards retail sales."
    ]
  },
  {
    title: "Sales Executive",
    location: "Multiple Locations",
    jobType: "Full-time",
    experience: "Open to both fresher and experienced candidates",
    qualification: "Plus Two",
    gender: "Male/Female",
    description: "Conduct follow-up calls to customers post-purchase, ensuring satisfaction and addressing any concerns.",
    responsibilities: [
      "Conduct follow-up calls to customers’ post-purchase, ensuring satisfaction and addressing any concerns.",
      "Collect feedback to improve customer experience and loyalty.",
      "Build lasting relationships with customers by resolving issues promptly.",
      "Identify opportunities for cross-selling and promoting additional services or products."
    ],
    requirements: [
      "Age: 20-30 years.",
      "Strong communication and interpersonal abilities.",
      "Customer-focused mindset with problem-solving skills.",
      "Basic understanding of Jewellery products is a plus."
    ]
  }
];

function Company1() {
  return (
    <div className="company-container">
      <header className="company-header">
        <h1 className="company-title">Kalyan Jewellers</h1>
        {/* <img src="logo.png" alt="Company Logo" className="company-logo" /> */}
      </header>
      
      <section className="job-listings">
        {jobs.map((job, index) => (
          <section key={index} className="job-section">
            <h2 className="job-title">{job.title}</h2>
            <div className="job-details">
              <p><strong>Location:</strong> {job.location}</p>
              <p><strong>Job Type:</strong> {job.jobType}</p>
              <p><strong>Experience:</strong> {job.experience}</p>
              <p><strong>Qualification:</strong> {job.qualification}</p>
              <p><strong>Gender:</strong> {job.gender}</p>
            </div>
            <h3>Job Description:</h3>
            <p>{job.description}</p>
            <h3>Responsibilities:</h3>
            <ul>
              {job.responsibilities.map((res, i) => <li key={i}>{res}</li>)}
            </ul>
            <h3>Requirements:</h3>
            <ul>
              {job.requirements.map((req, i) => <li key={i}>{req}</li>)}
            </ul>
          </section>
        ))}
      </section>

      <footer className="apply-section">
        <a href="https://forms.gle/sw88CzWEMzgBhEUt6" target="_blank" rel="noopener noreferrer">
          <button className="apply-button">Apply Now</button>
        </a>
      </footer>
    </div>
  );
}

export default Company1;
