import "./Event.css";
import { useEffect, useState } from "react";
import { Button } from './Button';
import { Link } from 'react-router-dom';
import { FaCheckCircle } from "react-icons/fa";

const ongoingEvent = {
  title: "Mega Job Edu Fair",
  date: "April 4 , 2025",
  // location: "Tech Arena, New York",
  image: "images/you_are.png",
  // description: "Experience the latest innovations in technology, AI, and robotics.",
};

const completedEvents = [

  {
    title: "Event Brochure",
    date: "Annual Day Hungama - YouTube Video Competition",
    image: "images/andh.png",
    description: `Peter's Foundation brought a unique opportunity for schools and colleges to showcase their students' talents and gain visibility across a wide audience.`,
    highlights: [
      "Boosted institution's online presence and community engagement.",
      "Showcased students’ remarkable talents and achievements.",
      "Videos with the highest views won exciting prizes!",
    ],
    message: "Thank you for participating and making it a grand success!",
  },
  {
    title: "Painting Exhibition",
    date: "Honoring the Legacy of Mr. Peter",
    image: "images/artexh.png",
    description: `In 2016, Mr. Peter’s extraordinary talent as an artist was celebrated at an exclusive exhibition held at the Poonam International Hotel, Thrissur. 
    Organized with the goal of sharing his creative brilliance, the event showcased a captivating collection of his paintings, each reflecting his unique perspective and unparalleled artistic vision.`,
    highlights: [
      "The exhibition was graced by distinguished officials and art connoisseurs.",
      "A special painting was gifted to retired Colonel Sushil, honoring his contributions to the nation.",
      "The event celebrated Mr. Peter’s artistic brilliance and enduring legacy.",
    ],
    message: `Peter’s Foundation continues to preserve his legacy by fostering creativity, supporting aspiring artists, and making a meaningful impact on the world.`,
  },
];

export default function EventsPage() {
  const calculateTimeLeft = () => {
    const eventDate = new Date("April 04, 2025").getTime();
    const now = new Date().getTime();
    const difference = eventDate - now;

    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <>
      <div className="events-container">
        <section className="ongoing-event">
          <img src={ongoingEvent.image} alt="Ongoing Event" className="featured-image" />
          <div className="event-overlay">
            <div className="event-info">
              <h3 className="zen-dots-regular">{ongoingEvent.title}</h3>
              <div className="countdown">
                <span>{timeLeft.days} Days</span> | 
                <span>{timeLeft.hours} Hours</span> | 
                <span>{timeLeft.minutes} Mins</span> | 
                <span>{timeLeft.seconds} Secs</span>
              </div>
              <p className="date"><strong>Date:</strong> {ongoingEvent.date}</p>
              <div className="event-buttons">
                <Link to="https://forms.gle/sw88CzWEMzgBhEUt6">
                  <Button className="btns" buttonStyle="btn--outline" buttonSize="btn--large">
                    PARTICIPANT
                  </Button>
                </Link>
                <Link to="https://wa.me/9072939555text=Hello%20I%20am%20interested%20in%20your%20event!">
                  <Button className="btns" buttonStyle="btn--outline" buttonSize="btn--large">
                    COMPANY
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </section>
        
        <section className="event-details">
          <p>A transformative event designed to empower students, fresh graduates, and professionals by connecting them with remarkable opportunities.</p>
          <h3>What’s in Store?</h3>
          <ul>
            <li><strong>Study Abroad Opportunities:</strong> Meet university representatives.</li>
            <li><strong>Study in India:</strong> Interact with college representatives.</li>
            <li><strong>Job Offers:</strong> Network with HRs from 20+ companies.</li>
            <li><strong>Loan Assistance:</strong> Receive guidance on education and career loans.</li>
            <li><strong>CV Counseling and Career Guidance.</strong></li>
            <li><strong>Mock Interviews</strong> with expert feedback.</li>
          </ul>
          <h3>Why Attend?</h3>
          <ul>
            <li>Access to exclusive career and educational opportunities.</li>
            <li>Direct interaction with HRs and educational representatives.</li>
            <li>Free participation with full support from Peter’s Foundation.</li>
          </ul>
          <p><strong>Join us and take the next step toward your brighter future!</strong></p>
        </section>
      </div>

      <div className="completed-events-container">
        <h1 className="completed-events-title">Completed Events</h1>
        <div className="completed-events-grid">
          {completedEvents.map((event, index) => (
            <div key={index} className="completed-event-card">
              <img src={event.image} alt={event.title} className="completed-event-image" />
              <h2 className="completed-event-title">{event.title}</h2>
              <p className="completed-event-date"> {event.date}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
